import React from "react";

import "antd/dist/antd.css";
import "./App.scss";
import "./InitializeFirebase";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { AuthProvider } from "./UserContext";
import { MainPage } from "./main-page/MainPage";
import { AuthStateHandler } from "./AuthStateHander";
import ProjectView from "./project-view/ProjectView";
import { WorkspaceView } from "./main-page/WorkspaceView";

const App = () => (
  <AuthProvider>
    <AuthStateHandler />
    <Router>
      <Switch>
        <Route path="/project/:projectId">
          <ProjectView />
        </Route>
        <Route path="/workspace/:workspaceId">
          <WorkspaceView />
        </Route>
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </Router>
  </AuthProvider>
);

export default App;
