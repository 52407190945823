import React, { ChangeEvent, useContext, useEffect } from "react";
import { Input } from "antd";
import firebase from "firebase";
import { ProjectInfoContext } from "./contexts/ProjectInfoContext";

import "./ProjectNameBox.scss";

const ProjectNameBox = () => {
  const { id, info } = useContext(ProjectInfoContext);

  const projectNameRef = firebase.database().ref(`projects/${id}/info/name`);
  const onInputChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    await projectNameRef.set(e.target.value);
  };

  useEffect(() => {
    document.title = info.name + " | Flagon";
  }, [info]);

  return (
    <Input
      value={info.name}
      onChange={onInputChanged}
      className="project-name"
    />
  );
};

export { ProjectNameBox };
