export const renderModes: { [key in RenderMode]: RenderModeInfo } = {
  digital: {
    title: "Digital",
    extraWidthmm: 0,
    extraHeightmm: 0,
  },
  "print-dual": {
    title: "Print (dual page)",
    extraWidthmm: 6,
    extraHeightmm: 6,
  },
};

export type RenderMode = "digital" | "print-dual";

export type RenderModeInfo = {
  title: string;
  extraWidthmm: number;
  extraHeightmm: number;
};

export const getRenderMode = (
  renderMode: RenderMode | undefined
): RenderModeInfo => {
  const rm = renderMode ?? "digital";
  return renderModes[rm] ?? renderModes["digital"];
};
