import React, { useState } from "react";

export type EditorSizeInfo = {
  width: number | null;
  setWidth: (w: number) => void;
  resizing: boolean;
  setResizing: (r: boolean) => void;
};

const defaultEditorSizeInfo = {
  width: null,
  resizing: false,
  setWidth: () => console.log("Editor size context not initialised"),
  setResizing: () => console.log("Editor size context not initialised"),
};

const EditorSizeContext = React.createContext<EditorSizeInfo>(
  defaultEditorSizeInfo
);

const EditorSizeProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [width, setWidth] = useState<number | null>(null);
  const [resizing, setResizing] = useState<boolean>(false);

  return (
    <EditorSizeContext.Provider
      value={{ width, setWidth, resizing, setResizing }}
    >
      {children}
    </EditorSizeContext.Provider>
  );
};

export { EditorSizeContext, EditorSizeProvider };
