import React from "react";
import { Layout, Menu } from "antd";
import { Header } from "./Header";
import { ItemType } from "antd/es/menu/hooks/useItems";

const PageFrame = ({
  headerItems,
  children,
}: {
  headerItems: ItemType[];
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <>
      <Layout>
        <Header narrow>
          <Menu theme="dark" mode="horizontal" selectable={false} items={headerItems}>
          </Menu>
        </Header>
        <Layout>{children}</Layout>
      </Layout>
    </>
  );
};

export { PageFrame };
