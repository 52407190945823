import React, { useContext, useState } from "react";
import { ProjectInfoContext } from "./ProjectInfoContext";

const OpenDocumentProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { info } = useContext(ProjectInfoContext);

  const [openDocument, setOpenDocument] = useState<OpenDocumentId>(
    info.main ?? null
  );

  const [openDocumentData, setOpenDocumentData] = useState("");

  return (
    <OpenDocumentContext.Provider
      value={{
        openDocument,
        setOpenDocument,
        openDocumentData,
        setOpenDocumentData,
      }}
    >
      {children}
    </OpenDocumentContext.Provider>
  );
};

type OpenDocumentId = string | null;
type OpenDocumentInfo = {
  openDocument: OpenDocumentId;
  setOpenDocument: (value: OpenDocumentId) => void;
  openDocumentData: string;
  setOpenDocumentData: (d: string) => void;
};
const defaultOpenDocumentInfo = {
  openDocument: null,
  setOpenDocument: () => {},
  openDocumentData: "",
  setOpenDocumentData: () => {},
};

const OpenDocumentContext = React.createContext<OpenDocumentInfo>(
  defaultOpenDocumentInfo
);

export { OpenDocumentProvider, OpenDocumentContext };
