import React from "react";
import { message } from "antd";
import {
  cachedHelpers,
  cachedRefs,
  makeRefs,
  ProjectRefs,
} from "../rendering/helpers";
import { getDuplicatePaths } from "../rendering/validation";
import JSZip from "jszip";
import { computeFileTree } from "../project-view/fileTree";
import { FileOperations, Files } from "../project-view/fileOperations";
import { RenderingContext } from "../rendering/renderingContext";
import { getDocContents } from "../rendering/render";
import { ProjectInfo } from "../databaseHelpers";

/**
 * Asynchronously construct and download a zip file containing all files in
 * the project.
 * @param project The current project.
 * @param files The files list in the current project.
 * @param fileOperations A computed set of file operations. Only getResourceURL is used here.
 */
const downloadZip = async (
  project: ProjectInfo & { id: string },
  files: Files,
  fileOperations: FileOperations
) => {
  const { getResourceURL } = fileOperations;

  // Duplicate check
  makeRefs(project.id);
  const projectRefs = cachedRefs!;
  const { keyToPath } = cachedHelpers!;

  const duplicates = getDuplicatePaths(keyToPath);
  if (duplicates.length > 0) {
    message.error(
      <div className="duplicate-file-error">
        <p>A ZIP could not be produced.</p>
        <p>There are documents with the same fully qualified path:</p>
        <ul>
          {duplicates.map(({ path, pid, count }, i) => (
            <li key={i}>
              <code>{path}</code> appears {count} times in{" "}
              <code>${projectRefs[pid].info.name}</code>
            </li>
          ))}
        </ul>
        <p>Rename any duplicated filenames before proceeding.</p>
      </div>,
      5
    );
    return;
  }

  // Construct Zip file

  const zip = new JSZip();

  const fileTree = computeFileTree(
    files ?? {},
    null,
    () => { },
    () => { }
  );

  // Add a single file or directory to the project.
  const addFile: (key: string, parent: JSZip) => Promise<void> = async (
    key,
    parent
  ) => {
    const file = files![key];
    const tasks: (() => Promise<void>)[] = [];

    switch (file.type) {
      case "document":
        const docString = await getDocContents(project.id, key);
        parent.file(file.name, docString);
        break;
      case "resource":
        const resourceData = await (await fetch(getResourceURL(key))).blob();
        parent.file(file.name, resourceData, { binary: true });
        break;
      case "dir":
        const dir = zip.folder(file.name)!;
        await Promise.all(
          Object.keys(file.children ?? {}).map((key: string) =>
            addFile(key, dir)
          )
        );
        break;
    }

    await Promise.all(tasks);
  };

  await Promise.all(fileTree.map((node) => addFile(node.key as string, zip)));

  const blob = await zip.generateAsync({ type: "blob" });
  window.saveAs(blob, `${project.name}.zip`);
};

export { downloadZip };
