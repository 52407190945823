import { Input, Modal } from "antd";
import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { createEmptyProject } from "../databaseHelpers";
import { UserContext } from "../UserContext";

const NewProjectModal = ({
  visible,
  close,
  parentWorkspace,
}: {
  visible: boolean;
  parentWorkspace?: string;
  close: () => void;
}) => {
  const user = useContext(UserContext);
  const [name, setName] = useState("");

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [newProjectId, setNewProjectId] = useState<string | null>(null);

  const createProject = useCallback(async () => {
    console.log("Creating");
    setConfirmLoading(true);
    if (!user) {
      console.warn("No user");
      setConfirmLoading(false);
      return;
    }
    const newProjectId = await createEmptyProject(
      name,
      user.uid,
      parentWorkspace
    );
    setNewProjectId(newProjectId);
    setConfirmLoading(false);
  }, [name, user, parentWorkspace]);

  return (
    <>
      <Modal
        open={visible}
        title="Create Project"
        onCancel={close}
        onOk={createProject}
        okButtonProps={{ disabled: name.length <= 3 }}
        confirmLoading={confirmLoading}
      >
        <Input
          placeholder="e.g. The Ten Habits of Highly Effective Elves"
          onChange={onInputChange}
          value={name}
        />
      </Modal>
      {newProjectId !== null && <Redirect to={`/project/${newProjectId}`} />}
    </>
  );
};

export { NewProjectModal };
