import { useContext } from "react";
import { ProjectInfoContext } from "./contexts/ProjectInfoContext";
import { getPageSize, getTotalSize, PageSize } from "../page-sizes";
import { RenderMode } from "../render-modes";

/**
 * This component dynamically loads a small piece of styling, which tells the
 * browser what "size" of page it should generate.
 *
 * This is necessary because CSS offers no way to switch page size based on
 * classes or other property easily changed via JSX.
 */

const PageSizeLoader = () => {
  const project = useContext(ProjectInfoContext);

  const pageMode = project.info.pageSize;
  const renderMode = project.info.renderMode;

  const innerSize = getPageSize(pageMode as PageSize);
  const size = getTotalSize(pageMode as PageSize, renderMode as RenderMode);

  return (
    <style>
      {`

      :root {
        --page-inner-width: ${innerSize.widthmm}mm;
        --page-inner-height: ${innerSize.heightmm}mm;
        --page-outer-width: ${size.widthmm}mm;
        --page-outer-height:${size.heightmm - 0.1}mm;
      }

      @media print {
        @page {
          size: ${size.widthmm}mm ${size.heightmm}mm;
        }
      }`}
    </style>
  );
};

export { PageSizeLoader };
