import { renderingError } from "./error";
import { ProjectRefs } from "./helpers";
import { RenderingContext, RenderingHelpers } from "./renderingContext";

const resolveImageTag = (
  pid: string,
  projectRefs: ProjectRefs,
  helpers: RenderingHelpers
) => {
  const { resourcesByPath } = helpers;

  return (imgTag: string) => {
    const src = matchSourceInTag(imgTag);
    if (src === undefined) return imgTag;

    const isRemote = src.match(/https?:\/\//i) != null;
    const isLocal = !isRemote;

    if (isLocal) {
      const key = resourcesByPath[pid][src];
      if (key === undefined) {
        throw renderingError(
          "You cannot access a nonexistent resource (img tag):",
          [
            <>
              <code>{src}</code> does not exist in{" "}
              <code>{projectRefs[pid].info.name}</code>.
            </>,
          ],
          <>
            Check your spelling or remove the URL from the document.
            <br />
            Ensure that URLs for remote files start with <code>
              http://
            </code> or <code>https://</code>.
          </>
        );
      }

      imgTag = imgTag.replace(
        srcRegex,
        `src="${projectRefs[pid].getResourceURL(key)}"`
      );
    }

    return imgTag;
  };
};

/**
 * A generally useful regex for finding the src attribute in a HTML tag.
 */
const srcRegex = /src ?= ?(["'][^"'>]*["'])/;

const matchSourceInTag = (tag: string): string | undefined => {
  // NOTE: URLs and local files may not contain single or double quotes

  const srcMatch = tag.match(srcRegex);

  if (srcMatch === null) return undefined;

  let src = srcMatch[1];
  if (src.charAt(0) === '"' && src.charAt(src.length - 1) === '"')
    src = src.slice(1, -1);
  if (src.charAt(0) === "'" && src.charAt(src.length - 1) === "'")
    src = src.slice(1, -1);

  return src;
};

export { resolveImageTag, matchSourceInTag };
