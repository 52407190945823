import firebase from "firebase";
import React, { useContext, useMemo } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import fileOperations, {
  defaultFileOperations,
  FileOperations,
  Files,
} from "../fileOperations";
import { ProjectInfoContext } from "./ProjectInfoContext";

export type FilesInfo = {
  files: Files | undefined;
};
const defaultFiles = { files: undefined, resourceURIs: undefined };
const FilesContext = React.createContext<FilesInfo>(defaultFiles);

const FilesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { id } = useContext(ProjectInfoContext);
  const filesRef = firebase.database().ref(`projects/${id}/files`);
  const [files] = useObjectVal<Files>(filesRef);

  const fileOps = useMemo<FileOperations>(
    () => fileOperations(id, files),
    [id, files]
  );

  return (
    <FilesContext.Provider value={{ files }}>
      <FileOperationsContext.Provider value={fileOps}>
        {children}
      </FileOperationsContext.Provider>
    </FilesContext.Provider>
  );
};

const FileOperationsContext = React.createContext<FileOperations>(
  defaultFileOperations
);

export { FilesContext, FilesProvider, FileOperationsContext };
