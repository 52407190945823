import React, { useState } from "react";

type UploadModalInfo = {
  visible: boolean;
  setVisible: (v: boolean) => void;
};

const UploadModalContext = React.createContext<UploadModalInfo>({
  visible: false,
  setVisible: () => {},
});

const UploadModalProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  return (
    <UploadModalContext.Provider
      value={{ visible: uploadModalVisible, setVisible: setUploadModalVisible }}
    >
      {children}
    </UploadModalContext.Provider>
  );
};

export { UploadModalProvider, UploadModalContext };
