import { Modal, Button, Select } from "antd";
import { useContext, useState, useCallback } from "react";
import {
  SimpleUser,
  getUsersByDisplayName,
  getUsersByGithubLogin,
  createUserInvite,
  ShareType,
} from "../databaseHelpers";

const AddCollaboratorModal = ({
  visible,
  setVisible,
  id,
  type,
}: {
  visible: boolean;
  setVisible: (v: boolean) => void;
  id: string;
  type: ShareType;
}) => {
  const [searchData, setSearchData] = useState<SimpleUser[]>([]);

  const onSearch = useCallback(async (search: string) => {
    setSearchData([]);
    if (search.length < 3) return;
    const allUsers = (
      await Promise.all<SimpleUser[]>([
        getUsersByDisplayName(search),
        getUsersByGithubLogin(search),
      ])
    ).flat();

    setSearchData(allUsers);
  }, []);

  const onChange = useCallback(
    async (uid: string) => {
      setVisible(false);
      createUserInvite(type, id, uid);
    },
    [id, setVisible]
  );

  return (
    <Modal
      className="add-collaborator"
      title="Add Collaborator"
      open={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="cancel" type="ghost" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
      ]}
    >
      <p>Write the user's GitHub display name in the box below.</p>
      <Select
        showSearch
        value={""}
        autoClearSearchValue
        showArrow={false}
        placeholder={"Find by name..."}
        onSearch={onSearch}
        // onChange={(v: SelectValue) => setValue(v.toString())}
        notFoundContent={null}
        className="add-collaborator"
        filterOption={false}
        defaultActiveFirstOption={false}
        onChange={(v) => onChange(v)}
        onBlur={() => {
          onSearch("");
        }}
      >
        {searchData.map(({ uid, displayName, githubLogin }) => (
          <Select.Option key={uid} value={uid}>
            {displayName ?? "[no display name]"} ({githubLogin})
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export { AddCollaboratorModal };
