import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

const defaultFileOperations = {
  moveFile: () => console.error("Cannot move - files not initialised!"),
  renameFile: () => console.error("Cannot rename - files not initialised!"),
  deleteFile: () => console.error("Cannot delete - files not initialised!"),
  getParent: () => {
    console.error("Cannot get parent - files not initialised!");
    return null;
  },
  createDocument: () => console.error("Cannot create - files not initialised!"),
  createDir: () => console.error("Cannot create - files not initialised!"),
  createSource: () => console.error("Cannot create - files not initialised!"),
  getResourceURL: () => {
    console.error("Cannot get URL - files not initialised!");
    return "Files not initialised";
  },
  getFullPath: () => {
    console.error("Cannot get path - files not initialised!");
    return "Files not initialised";
  },
};

const fileOperations = (
  projectId: string,
  files: Files | undefined
): FileOperations => {
  if (files === undefined) return defaultFileOperations;

  const filesRef = firebase.database().ref(`projects/${projectId}/files`);

  // Given a file listing, locate the parent directory (if one exists).
  const getParent = (child: string): string | null => {
    for (let [key, file] of Object.entries(files)) {
      if (Object.keys(file.children ?? {}).includes(child)) {
        return key;
      }
    }
    return null;
  };

  const moveFile = async (key: string, newParent: string | null) => {
    const oldParent = getParent(key);

    console.log(oldParent, newParent);

    // If the file hasn't really moved, do nothing
    if (newParent === oldParent) return;

    // Perform move
    if (oldParent !== null) {
      await filesRef.child(`${oldParent}/children/${key}`).remove();
    }
    if (newParent !== null) {
      await filesRef.child(`${newParent}/children/${key}`).set(true);
    }
  };

  const deleteFile = async (key: string) => {
    const file = files?.[key];
    if (file === null || file === undefined) {
      console.error(`Could not find file  with key ${key}`);
      return;
    }

    // Delete the resource itself
    // Any associated cleanup is performed in the cloud.

    const parent = getParent(key);
    if (parent !== null) {
      await filesRef.child(`${parent}/children/${key}`).remove();
    }

    await filesRef.child(key).remove();
  };

  const getResourceURL = (key: string) => {
    const file = files[key]!;
    const resource = file.resource!.split("/").slice(1).join("%2F");
    return `https://flagon-2.web.app/res/${resource}?alt=media`;
  };

  const renameFile = async (key: string, newName: string) => {
    await filesRef.child(key).update({ name: newName });
  };

  const createDocument = async (parentId?: string) => {
    const documentId = uuidv4();

    const fileRef = filesRef.child(documentId);

    const docRef = firebase
      .database()
      .ref(`projects/${projectId}/documents/${documentId}`);

    await fileRef.set({ name: "new-file.flagon", type: "document" });
    await docRef.set({ exists: true });
    if (parentId) await moveFile(documentId, parentId);
  };

  const createSource = async (
    ref?: string,
    name?: string,
    parentId?: string
  ) => {
    const documentId = uuidv4();
    const fileRef = filesRef.child(documentId);

    await fileRef.set({ name: name ?? "New Ref", type: "ref", ref: ref });
    if (parentId) await moveFile(documentId, parentId);
  };

  const createDir = async (parentId?: string) => {
    const documentId = uuidv4();
    const fileRef = filesRef.child(documentId);
    await fileRef.set({ name: "new folder", type: "dir" });

    if (parentId) await moveFile(documentId, parentId);
  };

  const getFullPath = (key: string) => {
    var fullPath = files[key]!.name;
    var node: string | null = key;
    while (true) {
      node = getParent(node!);
      if (node === null) break;
      fullPath = `${files[node]!.name}/${fullPath}`;
    }
    return fullPath;
  };

  return {
    moveFile,
    deleteFile,
    renameFile,
    getParent,
    createDocument,
    createDir,
    createSource,
    getResourceURL,
    getFullPath,
  };
};

export type File = {
  name: string;
  type: "dir" | "resource" | "document" | "ref";
  resource: string | undefined;
  ref: string | undefined;
  url: string | undefined;
  children: { [key: string]: boolean } | undefined;
};

export type Files = {
  [key: string]: File;
};

export type FileOperations = {
  moveFile: (key: string, newParent: string | null) => void;
  deleteFile: (key: string) => void;
  renameFile: (key: string, newName: string) => void;
  getParent: (key: string) => string | null;
  createDocument: (folder?: string) => void;
  createDir: (folder?: string) => void;
  createSource: (ref?: string, name?: string, folder?: string) => void;
  getResourceURL: (key: string) => string;
  getFullPath: (key: string) => string;
};

export default fileOperations;
export { defaultFileOperations };
