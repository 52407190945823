import { Input, Modal } from "antd";
import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { createEmptyWorkspace, setProjectWorkspace } from "../databaseHelpers";
import { UserContext } from "../UserContext";

const NewWorkspaceModal = ({
  visible,
  setVisible,
  projectId,
}: {
  visible: boolean;
  projectId: string;
  setVisible: (v: boolean) => void;
}) => {
  const user = useContext(UserContext);
  const [name, setName] = useState("");

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const createWorkspace = useCallback(async () => {
    console.log("Creating");
    setConfirmLoading(true);
    if (!user) {
      console.warn("No user");
      setConfirmLoading(false);
      return;
    }
    const workspaceId = await createEmptyWorkspace(name, user.uid);
    await setProjectWorkspace(projectId, workspaceId);
    setConfirmLoading(false);

    setVisible(false);
  }, [name, user, setVisible, projectId]);

  return (
    <>
      <Modal
        open={visible}
        title="Create Workspace"
        onCancel={() => setVisible(false)}
        onOk={createWorkspace}
        okButtonProps={{ disabled: name.length <= 3 }}
        confirmLoading={confirmLoading}
      >
        <Input
          placeholder="e.g. Adventuring with Pride: Queer We Go Again!"
          onChange={onInputChange}
          value={name}
        />
      </Modal>
    </>
  );
};

export { NewWorkspaceModal };
