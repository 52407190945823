import firebase from "firebase";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";

const UserContext = React.createContext<firebase.User | null>(null);

const AuthProvider = ({ children }: any) => {
  const [user] = useAuthState(firebase.auth());
  const user_ = user ?? null;
  return <UserContext.Provider value={user_}>{children}</UserContext.Provider>;
};

export { UserContext, AuthProvider };
