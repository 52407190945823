import React, { RefObject, useRef } from "react";

export type PaneInfo = {
  outputPane: RefObject<HTMLDivElement> | null;
  editorPane: RefObject<HTMLDivElement> | null;
  filePane: RefObject<HTMLDivElement> | null;
};

const defaultPaneInfo = {
  outputPane: null,
  editorPane: null,
  filePane: null,
};

const PaneContext = React.createContext<PaneInfo>(defaultPaneInfo);

const PaneProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const outputPane = useRef<HTMLDivElement>(null);
  const editorPane = useRef<HTMLDivElement>(null);
  const filePane = useRef<HTMLDivElement>(null);

  return (
    <PaneContext.Provider value={{ outputPane, editorPane, filePane }}>
      {children}
    </PaneContext.Provider>
  );
};

export { PaneContext, PaneProvider };
