import React from "react";
import {
  PictureOutlined,
  FileTextOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { DataNode } from "antd/lib/tree";
import { File, Files } from "./fileOperations";

// Given a file listing, construct a tree structure that antd understands.
const computeFileTree = (
  files: Files,
  renameTarget: string | null,
  setRenameTarget: (target: string | null) => void,
  renameFile: (key: string, name: string) => void
): DataNode[] => {
  const filesParsed: { [key: string]: DataNode } = {};
  Object.entries(files).forEach(([key, file]: [string, File]) => {
    const title = file.name;
    const isLeaf = file.type !== "dir";

    let icon: JSX.Element | undefined = undefined;
    if (file.type === "resource") {
      if (file.name.match(/.jpg$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.gif$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.png$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.svg$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.bmp$/i)) icon = <PictureOutlined />;
    } else if (file.type === "document") {
      icon = <FileTextOutlined />;
    } else if (file.type === "ref") {
      icon = <LinkOutlined />;
    }

    filesParsed[key] = { key, title, icon, isLeaf };
  });

  if (renameTarget !== null) {
    const done = (e: any) => {
      renameFile(renameTarget, e.target.value);
      setRenameTarget(null);
    };
    // Do something here
    filesParsed[renameTarget].title = (
      <Input
        defaultValue={files[renameTarget].name}
        onKeyUp={(e) => e.key === "Enter" && done(e)}
        onBlur={done}
        autoFocus
      />
    );
  }

  // Sort child entries by name, putting directories first.
  const sortNodeList = (nodeList: DataNode[]) => {
    nodeList.sort((a: DataNode, b: DataNode) => {
      const fa = files[a.key]!;
      const fb = files[b.key]!;
      const typeValue = (type: "document" | "resource" | "ref" | "dir") => {
        switch (type) {
          case "dir":
            return 1;
          case "ref":
            return 2;
          case "document":
            return 3;
          case "resource":
            return 4;
        }
      };
      const typeDiff = typeValue(fa.type) - typeValue(fb.type);
      if (typeDiff == 0) return fa.name.localeCompare(fb.name);
      else {
        return typeDiff;
      }
    });
    return nodeList;
  };

  // Start with all nodes at the top level and remove them if we find them as children in the next step.
  const topLevelNodesSet = new Set(Object.keys(files));

  // Set the children of all nodes
  Object.entries(filesParsed).forEach(([key, file]: [string, DataNode]) => {
    file.children = sortNodeList(
      Object.keys(files[key].children ?? {}).map((k) => {
        topLevelNodesSet.delete(k);
        return filesParsed[k];
      })
    );
  });

  // Sort the root directory.
  const topLevelNodes = sortNodeList(
    Array.from(topLevelNodesSet).map((k) => filesParsed[k])
  );

  return topLevelNodes;
};

export { computeFileTree };
