import { getRenderMode, RenderMode } from "./render-modes";

export const pageSizes: { [key in PageSize]: PageSizeInfo } = {
  a4: {
    title: "A4 paper",
    size: "210mm 297mm",
    widthmm: 210,
    heightmm: 297,
  },
  "rpg-book": {
    title: "RPG book",
    size: "210mm 275mm",
    widthmm: 210,
    heightmm: 275,
  },
};

export type PageSize = "a4" | "rpg-book";

export type PageSizeInfo = {
  title: string;
  size: string;
  widthmm: number;
  heightmm: number;
};

export const getPageSize = (pageSize: PageSize | undefined): PageSizeInfo => {
  const ps = pageSize ?? "rpg-book";

  return pageSizes[ps] ?? pageSizes["rpg-book"];
};

export const getTotalSize = (
  pageSize: PageSize | undefined,
  renderMode: RenderMode | undefined
) => {
  const p = getPageSize(pageSize);
  const r = getRenderMode(renderMode);

  return {
    widthmm: p.widthmm + r.extraWidthmm,
    heightmm: p.heightmm + r.extraHeightmm,
  };
};
