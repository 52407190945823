import React, { useState } from "react";

const RenderProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [renderFunc, setRenderFunc] = useState<
    (clearCache: boolean) => Promise<boolean>
  >(async () => false);

  return (
    <RenderContext.Provider value={{ renderFunc, setRenderFunc }}>
      {children}
    </RenderContext.Provider>
  );
};

type RenderInfo = {
  renderFunc: (clearCache: boolean) => Promise<boolean>;
  setRenderFunc: (_: (clearCache: boolean) => Promise<boolean>) => void;
};
const defaultRenderInfo = {
  renderFunc: async () => false,
  setRenderFunc: () => {},
};

const RenderContext = React.createContext<RenderInfo>(defaultRenderInfo);

export { RenderProvider, RenderContext };
