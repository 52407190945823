import React, { useContext } from "react";
import { Menu, Layout } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import FilesMenu from "./FilesMenu";
import { ProjectSettingsDrawerContext } from "./contexts/ProjectSettingsDrawerContext";

const Sidebar = () => {
  const projectSettingsDrawer = useContext(ProjectSettingsDrawerContext);
  return (
    <Layout.Sider className="sidebar" width={200}>
      <FilesMenu />
      <Menu selectable={false} items={[{
        key: "settingsDrawerVisible",
        onClick: () => projectSettingsDrawer.setVisible(true),
        icon: <SettingOutlined />,
        label: "Project settings"
      }]} />
    </Layout.Sider>
  );
};

export { Sidebar };
