import React from "react";
import { Layout, Menu } from "antd";

import "./ProjectView.scss";
import UploadModal from "./UploadModal";

import { ProjectViewProviderStack } from "./contexts/ProjectViewProviderStack";

import { ProjectSettingsDrawer } from "./ProjectSettingsDrawer";
import { EditorResizer } from "./EditorResizer";
import { Sidebar } from "./Sidebar";
import { Header } from "../Header";
import EditorPane from "./Editor";
import Output from "./Output";

import "./ProjectView.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ProjectNameBox } from "./ProjectNameBox";
import { ProjectInfoContext } from "./contexts/ProjectInfoContext";
import { useContext } from "react";
import { PageSizeLoader } from "./PageSizeLoader";

const ProjectView = () => {
  return (
    <ProjectViewProviderStack>
      <Layout className="project-view">
        <Header>
          <Menu theme="dark" mode="horizontal" selectable={false} items={[{ key: "return", icon: <ArrowLeftOutlined />, label: <ReturnLink /> }]} />
          <ProjectNameBox />
        </Header>
        <Layout>
          <Sidebar />
          <Layout.Content is="main">
            <EditorPane />
            <Output />
            <EditorResizer />
            <PageSizeLoader />
          </Layout.Content>
          <UploadModal />
          <ProjectSettingsDrawer />
        </Layout>
      </Layout>
    </ProjectViewProviderStack>
  );
};

const ReturnLink = () => {
  const proj = useContext(ProjectInfoContext);
  const url = !!proj?.info?.workspace
    ? `/workspace/${proj.info.workspace}`
    : `/`;
  return <Link to={url}>Projects</Link>;
};

export default ProjectView;
