import React, { useCallback, useContext, useEffect } from "react";
import { setUserDisplayName, setUserGithubLogin } from "./databaseHelpers";
import { UserContext } from "./UserContext";

const AuthStateHandler = () => {
  const GITHUB_USER_ENDPOINT = "https://api.github.com/user";

  const user = useContext(UserContext);

  const updateUserIdentifiers = useCallback(async (user: any) => {
    const uid = user.uid;
    const displayName = user?.displayName;
    const githubUid = user?.providerData?.[0]?.uid;

    if (displayName) {
      setUserDisplayName(uid, displayName);
    }

    // If we have a user id, then get the user login name
    // from the github user API. This user *should* be authenticated to do so.
    if (githubUid) {
      try {
        const githubData = await (
          await fetch(`${GITHUB_USER_ENDPOINT}/${githubUid}`)
        ).json();

        const githubLogin = githubData.login;

        if (githubLogin) {
          // We only store the user's login, so it can be used in user searches. It will be deleted on request.
          setUserGithubLogin(uid, githubLogin);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  // I think this will only be run rarely
  // Update user's stored data in the db
  useEffect(() => {
    if (user) updateUserIdentifiers(user);
  }, [user, updateUserIdentifiers]);

  return <></>;
};

export { AuthStateHandler };
