import React, {
  ChangeEvent,
  ReactText,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
  Tooltip,
  Tree,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  LinkOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import "./FilesMenu.scss";

import { EventDataNode } from "antd/lib/tree";
import { OpenDocumentContext } from "./contexts/OpenDocumentContext";
import { UploadModalContext } from "./contexts/UploadModalContext";

import { FileOperationsContext, FilesContext } from "./contexts/FilesContext";
import { computeFileTree } from "./fileTree";
import { PaneContext } from "./contexts/PaneContext";
import { searchSources, SourceData } from "../databaseHelpers";
import { UserContext } from "../UserContext";
import { SourceInfo, SourceModal } from "./SourceModal";

const FilesMenu = () => {
  const { openDocument, setOpenDocument } = useContext(OpenDocumentContext);
  const { files } = useContext(FilesContext);
  const { setVisible } = useContext(UploadModalContext);
  const { filePane } = useContext(PaneContext);

  const [renameTarget, setRenameTarget] = useState<string | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<string | null>(null);
  const [lastMousedOver, setLastMousedOver] = useState<string | null>(null);
  const [contextTarget, setContextTarget] = useState<string | null>(null);
  const [previewModal, setPreviewModal] = useState<PreviewModalInfo>(null);

  const [sourceInfo, setSourceInfo] = useState<SourceInfo | null>(null);

  const {
    moveFile,
    deleteFile,
    renameFile,
    getParent,
    createDocument,
    createDir,
    createSource,
    getResourceURL,
  } = useContext(FileOperationsContext);

  const fileTree = useMemo(
    () =>
      computeFileTree(files ?? {}, renameTarget, setRenameTarget, renameFile),
    [renameTarget, files, renameFile]
  );

  const onMove = useCallback(
    async (info: any) => {
      // Do not move files while something is being renamed!
      if (renameTarget !== null) return;

      if (files === undefined) {
        console.error("Drop confirmed with an empty file list.");
        return;
      }

      let newParent = info.node.key;
      if (files[newParent].type !== "dir") {
        newParent = getParent(newParent);
      }

      moveFile(info.dragNode.key, newParent);
    },
    [files, renameTarget, getParent, moveFile]
  );

  const onSelect = useCallback(
    async ([key]: ReactText[]) => {
      // Don't switch while a file is being renamed
      if (renameTarget !== null) return;

      const file = files?.[key];
      if (file === undefined) return;

      switch (file.type) {
        case "dir":
          return;
        case "resource":
          if (file.resource === undefined) {
            console.error(
              `file ${file.name} does not have an associated resource`
            );
            return;
          }
          const url = await getResourceURL(key.toString());
          setPreviewModal({
            body: <img alt="" src={url} />,
            title: file.name,
          });
          break;
        case "document":
          const fileId = key.toString();
          setOpenDocument(fileId === openDocument ? null : fileId);
          break;
        case "ref":
          window.location.href = `/project/${file.ref}`;
          break;
      }
    },
    [
      setPreviewModal,
      files,
      openDocument,
      renameTarget,
      getResourceURL,
      setOpenDocument,
    ]
  );

  const onMouseOver = useCallback(({ node }: { node: EventDataNode<any> }) => {
    const key = node.key.toString();
    setLastMousedOver(key);
  }, []);

  const onMouseOut = useCallback(() => {
    setLastMousedOver(null);
  }, []);

  const file = useMemo(() => {
    if (!contextTarget) return null;
    else return files?.[contextTarget];
  }, [files, contextTarget]);

  const dropdownItems = useMemo(() => {
    if (!file) return [];
    let k = [
      {
        key: "rename", icon: <EditOutlined />, label: "Rename", onClick: () => setRenameTarget(contextTarget)
      },

      {
        key: "delete", icon: <DeleteOutlined />, label: "Delete", onClick: () => setDeleteTarget(contextTarget)
      }
    ];
    if (file?.type == "dir") {
      k.push({
        key: "new-dir", icon: <></>, label: "New folder", onClick: () => createDir(contextTarget ?? undefined)
      });
      k.push({
        key: "new-document", icon: <></>, label: "New document", onClick: () => createDocument(contextTarget ?? undefined)
      });
    }

    return k;
  }
    , [file, contextTarget]);

  const onContextMenuVisible = useCallback(
    (visible: boolean) => {
      if (visible) setContextTarget(lastMousedOver);
      else setContextTarget(null);
    },
    [lastMousedOver]
  );

  return (
    <div className="files-menu" ref={filePane}>
      <div className="title-row">
        {/* <h3 className="files-title">Files</h3> */}
        <div className="icon-area">
          <Tooltip title="New source">
            <Button
              onClick={() => {
                setSourceInfo({
                  ref: undefined,
                  name: undefined,
                });
              }}
            >
              <LinkOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="New folder">
            <Button onClick={() => createDir()}>
              <FolderAddOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="New file">
            <Button onClick={() => createDocument()}>
              <FileAddOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Upload file">
            <Button onClick={() => setVisible(true)}>
              <UploadOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <Divider />
      <Dropdown
        menu={{ items: dropdownItems }}
        // overlay={overlayFunc}
        trigger={["contextMenu"]}
        onVisibleChange={onContextMenuVisible}
      >
        <Tree.DirectoryTree
          defaultExpandAll
          draggable={true}
          treeData={fileTree}
          onDrop={onMove}
          onSelect={onSelect}
          virtual={true}
          selectedKeys={openDocument === null ? [] : [openDocument]}
          onMouseEnter={onMouseOver}
          onMouseLeave={onMouseOut}
        />
      </Dropdown>
      <PreviewModal {...{ previewModal, setPreviewModal }} />
      <DeleteModal {...{ deleteFile, deleteTarget, setDeleteTarget }} />
      <SourceModal {...{ sourceInfo, setSourceInfo }} />
    </div>
  );
};

const PreviewModal = ({
  previewModal,
  setPreviewModal,
}: {
  previewModal: PreviewModalInfo;
  setPreviewModal: (m: PreviewModalInfo) => void;
}) => (
  <Modal
    className="resource-preview"
    open={previewModal !== null}
    okText={"Close"}
    footer={null}
    title={previewModal?.title}
    onCancel={() => setPreviewModal(null)}
  >
    {previewModal?.body}
  </Modal>
);

const DeleteModal = ({
  deleteTarget,
  setDeleteTarget,
  deleteFile,
}: {
  deleteTarget: string | null;
  setDeleteTarget: (k: string | null) => void;
  deleteFile: (k: string) => void;
}) => {
  const { files } = useContext(FilesContext);

  return (
    <Modal
      open={deleteTarget !== null}
      onCancel={() => setDeleteTarget(null)}
      footer={[
        <Button
          key="delete"
          onClick={() => {
            if (deleteTarget === null) return;
            deleteFile(deleteTarget);
            setDeleteTarget(null);
          }}
          danger
          type="primary"
        >
          Yes, delete {files?.[deleteTarget ?? ""]?.name ?? "<UNKNOWN>"}
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to delete{" "}
        {files?.[deleteTarget ?? ""]?.name ?? "<UNKNOWN>"}?
      </p>
    </Modal>
  );
};

type PreviewModalInfo = { title: string; body: JSX.Element } | null;

export default FilesMenu;
