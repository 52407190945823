import React from "react";
import { Layout } from "antd";
import UserBox from "./UserBox";

import "./Header.scss";

const Header = ({
  children,
  narrow,
}: {
  children?: JSX.Element | JSX.Element[];
  narrow?: boolean;
}) => {
  return (
    <Layout.Header>
      <div className={narrow ? "narrow" : ""}>
        {children}
        <UserBox />
      </div>
    </Layout.Header>
  );
};

export { Header };
