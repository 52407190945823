import React from "react";
import firebase from "firebase";
import firepad from "firepad";
import { RenderingContext, toKey, toKeyHash } from "./renderingContext";
import { validateFilenames, validateImports } from "./validation";
import { cachedHelpers, cachedRefs, makeRefs } from "./helpers";
import { renderingError } from "./error";
import {
  cachedFragments,
  computeRelationships,
  computeRenderOrder,
  populatedFragments,
  populateFragment,
  renderFragment,
  reverseTransitiveClosure,
} from "./fragment";

/**
 * We track the last used project ID to see if a complete refresh is due.
 */
let lastProjectId: string | undefined = undefined;

const render = async (context: RenderingContext): Promise<string> => {
  const {
    projectId,
    main,
    outputRef,
    clearCache,
    docPrefix,
    openDocument,
    openDocumentData,
  } = context;

  // Debugging details
  console.log("Render started");

  let fullRender = clearCache;
  if (lastProjectId !== projectId) {
    lastProjectId = projectId;
    console.log("First run - forcing a full render");
    fullRender = true;
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  const renderStart = window.performance.now();

  // Some simple checks to make sure we can run the renderer now
  if (outputRef.current === null) {
    console.error("Tried to render without output pane reference defined");
    throw renderingError("There is no output pane defined");
  }

  if (main === undefined) {
    console.warn("No main document has been set.");
    throw renderingError(
      "This project has no main document.",
      [],
      "Set the main document in the project settings."
    );
  }

  // Grab unified project references
  if (fullRender || cachedRefs === undefined) await makeRefs(context.projectId);

  const projectRefs = cachedRefs!;
  const helpers = cachedHelpers!;

  const { documentKeysFlat } = helpers;

  // Check there are no documents with duplicate names
  validateFilenames(context, projectRefs, helpers);

  // We need to check all the hot documents to see if they've been updated.
  const hotDocuments: { pid: string; key: string }[] = [];
  hotDocuments.push({ pid: projectId, key: main });

  // On a full rebuild, all documents are refreshed.
  if (fullRender) {
    hotDocuments.push(...documentKeysFlat);
  } else {
    // If this is a soft rebuild, we only need to rerender the currently open
    // document.
    if (openDocument !== null) {
      hotDocuments.push({ pid: projectId, key: openDocument });
      // Render all documents which do not yet have a cached fragment.
      hotDocuments.push(
        ...documentKeysFlat.filter(
          (docKey) => cachedFragments[toKeyHash(docKey)] === undefined
        )
      );
    }
  }

  // This is the list of all hot documents whose fragments were changed.
  const updatedDocuments = (
    await Promise.all(
      hotDocuments.map(async (docKey) => {
        // Asynchronously grab the content and render it as a fragment
        let content =
          docKey.pid === projectId && docKey.key === openDocument
            ? openDocumentData
            : await getDocContents(docKey.pid, docKey.key);

        // Add the document prefix if it is the main document!
        if (main === docKey.key) {
          content = `${docPrefix ?? ""}\n\n${content}`;
        }

        const updated = renderFragment(
          projectRefs,
          context,
          helpers,
          docKey,
          content
        );

        return { docKey, updated };
      })
    )
  )
    .filter(({ updated }) => updated)
    .map(({ docKey }) => docKey);

  validateImports(context, projectRefs, helpers);

  // Compute the relationships between all documents.
  const relationships = computeRelationships(documentKeysFlat);

  const documentsToRepopulate = reverseTransitiveClosure(
    updatedDocuments,
    relationships
  );

  const renderOrder = computeRenderOrder(documentsToRepopulate, relationships);
  renderOrder.forEach((docKey) => populateFragment(projectRefs, docKey));

  const renderTime = window.performance.now() - renderStart;
  console.log(`Rendering completed in ${Math.round(renderTime)}ms`);

  // console.log(populatedFragments[main]);

  const mainFrag = populatedFragments[toKeyHash({ pid: projectId, key: main })];

  return `<div class="page"><div class="page-content">${mainFrag}</div></div>`;
};

/**
 * Get the contents of a document from the firebase database.
 * This is done by connecting with a headless firepad instance and asking it to
 * compute the text contents for the given reference.
 * @param projectId The current project ID.
 * @param key The key of the document to retrieve.
 */
const getDocContents = async (projectId: string, key: string) => {
  const docRef = firebase
    .database()
    .ref(`projects/${projectId}/documents/${key}`);

  let cacheIsValid = true;
  if (cachedFragments[key] === undefined) cacheIsValid = false;
  else {
    const hashSum = (await docRef.child("hashSum").get()).val();
    if (!hashSum) {
      cacheIsValid = false;
    } else {
      cacheIsValid = hashSum === cachedFragments[key].hashSum;
    }
  }

  if (cacheIsValid) return cachedFragments[key].raw;

  const doc = firepad.Headless(docRef);

  return await new Promise<string>((resolve, _) => {
    doc.getText((text: string) => {
      doc.dispose();
      resolve(text);
    });
  });
};

export { getDocContents, render };
