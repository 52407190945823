import { PlusOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Divider, Input, List, Modal, Popconfirm } from "antd";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useEffect } from "react";
import {
  deleteWorkspace,
  setWorkspaceName,
  useCollaborators,
  useShareUsers,
  useWorkspaceInfo,
} from "../databaseHelpers";
import { AddCollaboratorModal } from "../project-view/AddCollaboratorModal";
import { CollaboratorEntry } from "../project-view/CollaboratorEntry";
import { UserContext } from "../UserContext";

const EditWorkspaceModal = ({
  visible,
  close,
  workspaceId,
  afterDelete,
}: {
  visible: boolean;
  close: () => void;
  afterDelete: () => void;
  workspaceId: string | undefined;
}) => {
  const workspace = useWorkspaceInfo(workspaceId);
  const users = useShareUsers("workspace", workspaceId!);
  const user = useContext(UserContext);
  const [localName, setLocalName] = useState(workspace?.name ?? "");
  const [valid, setValid] = useState(true);
  const [collabModalVisible, setCollabModalVisible] = useState(false);

  const [collaborators, collaboratorsLoading] = useCollaborators(
    "workspace",
    workspaceId!
  );

  const isOwner = useMemo(() => users?.owner === user?.uid, [user, users]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value);
      if (workspaceId === undefined) return;

      const val = e.target.value;
      setLocalName(val);

      if (!(val.length >= 1)) {
        setValid(false);
        return;
      }
      setValid(true);

      setWorkspaceName(workspaceId, val);
    },
    [setValid, workspaceId]
  );

  useEffect(() => {
    if (workspace === undefined || workspace === null) return;
    setLocalName(workspace.name);
  }, [workspace]);

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  return workspaceId === undefined ? (
    <></>
  ) : (
    <>
      <Modal
        open={visible}
        title="Edit Workspace"
        onCancel={close}
        onOk={close}
        closable={valid}
        confirmLoading={confirmLoading}
        footer={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Popconfirm
              onConfirm={() => {
                deleteWorkspace(workspaceId);
                close();
                afterDelete();
              }}
              icon={<WarningFilled style={{ color: "red" }} />}
              title={
                <span>
                  Are you sure you want to delete this workspace?<br></br>The
                  projects in the workspace will NOT be deleted.
                </span>
              }
            >
              {" "}
              <Button danger type="primary">
                Delete Workspace
              </Button>
            </Popconfirm>

            <div style={{ flex: 1 }} />
            <Button onClick={close} disabled={!valid}>
              Cancel
            </Button>
            <Button onClick={close} type="primary" disabled={!valid}>
              OK
            </Button>
          </div>
        }
      >
        <Input onChange={onInputChange} value={localName} />

        <Divider />
        <div className="setting-group collaborators">
          <p className="setting-group-heading">Collaborators</p>

          <List
            className=""
            bordered
            size="small"
            dataSource={collaborators}
            loading={collaboratorsLoading}
            renderItem={(c) => (
              <CollaboratorEntry
                c={c}
                id={workspaceId}
                type="workspace"
                owner={users!.owner}
              />
            )}
          ></List>
          {isOwner && (
            <Button
              className="add-collaborator"
              type="ghost"
              onClick={() => setCollabModalVisible(true)}
              style={{ width: "100%", marginTop: "6px" }}
            >
              <PlusOutlined />
              Add Collaborator
            </Button>
          )}
        </div>
      </Modal>
      <AddCollaboratorModal
        type="workspace"
        id={workspaceId}
        visible={collabModalVisible}
        setVisible={setCollabModalVisible}
      />
    </>
  );
};

export { EditWorkspaceModal };
