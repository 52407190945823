export type RenderingError = {
  head: JSX.Element | string;
  lines: (JSX.Element | string)[];
  foot: JSX.Element | string;
  _isRenderingError: true;
};

export const renderingError = (
  head: JSX.Element | string = "",
  lines = [] as (JSX.Element | string)[],
  foot: JSX.Element | string = ""
): RenderingError => ({
  head,
  lines,
  foot,
  _isRenderingError: true,
});
