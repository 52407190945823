import React, { useState } from "react";

export type ProjectSettingsDrawerInfo = {
  visible: boolean;
  setVisible: (v: boolean) => void;
};

const defaultProjectSettingsDrawerInfo = {
  visible: false,
  setVisible: () =>
    console.log("Project settings drawer context not initialised"),
};

const ProjectSettingsDrawerContext = React.createContext<ProjectSettingsDrawerInfo>(
  defaultProjectSettingsDrawerInfo
);

const ProjectSettingsDrawerProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <ProjectSettingsDrawerContext.Provider value={{ visible, setVisible }}>
      {children}
    </ProjectSettingsDrawerContext.Provider>
  );
};

export { ProjectSettingsDrawerContext, ProjectSettingsDrawerProvider };
